@import './../../../../scss/theme-bootstrap';

.tab-container-block {
  width: 100%;
  max-width: 100%;
  padding-bottom: 80px;
  &.background_color_layout {
    &--light {
      background: $color-lighter-gray;
      .mpp__container {
        &--tabbed {
          .mpp__category-name {
            h2 {
              color: $color-black;
            }
            &--sub-header {
              color: $color-black;
            }
          }
        }
      }
      .tabbed-products-block {
        &__tabs {
          .tabbed-products-block__tab {
            background: $color-whisper-shade;
            color: $color-black;
            &:not(.current):hover {
              color: $color-gray;
              background: $color-white;
            }
            &.current {
              color: $color-black;
              background: $color-white;
            }
          }
        }
      }
    }
    &--dark {
      background: $color-almost-black;
      .mpp__container {
        &--tabbed {
          .mpp__category-name {
            h2 {
              color: $color-white;
              span:before,
              span:after {
                border-top-color: $color-button-dark-gray;
              }
            }
            &--sub-header {
              color: $color-white;
            }
          }
        }
      }
      .tabbed-products-block {
        &__tabs {
          .tabbed-products-block__tab {
            background: $color-button-dark-gray;
            color: $color-white;
            &:not(.current):hover {
              color: $color-gray;
              background: $color-white;
            }
            &.current {
              color: $color-black;
              background: $color-white;
            }
          }
        }
      }
    }
  }
  .mpp__container {
    &--tabbed {
      margin: 0 auto;
      @include breakpoint($medium-up) {
        padding-top: 60px;
        margin-top: 0;
        padding-bottom: 0;
      }
      .mpp__category-name {
        padding-bottom: 0;
        @include breakpoint($medium-up) {
          padding-bottom: 40px;
        }
        h2 {
          width: 80%;
          margin: 0 auto;
        }
        &--sub-header {
          font-size: 11px;
          letter-spacing: 0.13em;
          text-transform: uppercase;
          @include breakpoint($medium-up) {
            font-size: 13px;
          }
        }
      }
    }
  }
  .tabbed-products-block {
    max-width: 1280px;
    background: $color-white;
    margin: 0 auto;
    &__tabs {
      margin: 10px auto 0;
      text-align: center;
      @include breakpoint($medium-up) {
        margin: 20px auto 0;
      }
    }
    &__tabs-content {
      display: flex;
      flex-wrap: wrap;
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
    &__tab {
      transition: 0.3s ease-in-out;
      width: 100%;
      border-bottom: 1px solid $color-white;
      border-#{$rdirection}: 2px solid $color-white;
      cursor: pointer;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      -ms-flex-direction: inherit;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0;
      padding: 15px 10px;
      text-decoration: none;
      text-transform: uppercase;
      flex-grow: 1;
      width: calc(33.3333% - 2px);
      @include breakpoint($med-small-up) {
        font-size: 12px;
        letter-spacing: 0.1em;
      }
      @include breakpoint($medium-up) {
        font-size: 15px;
        padding: 25px 10px;
      }
      &:visited {
        text-decoration: none;
      }
      &:not(.current):hover {
        background: $color-white;
        border-bottom: 1px solid $color-white;
        border-#{$rdirection}: 2px solid $color-white;
        text-decoration: none;
      }
      &.current {
        border-bottom: 1px solid $color-white;
        border-#{$rdirection}: 2px solid $color-white;
        text-decoration: none;
        outline: none;
        &:hover {
          border-bottom: 1px solid $color-white;
        }
      }
      &.disabled {
        pointer-events: none;
        cursor: default;
      }
      &:last-child {
        border-#{$rdirection}: none;
        &:not(.current):hover {
          border-#{$rdirection}: none;
        }
      }
      &:first-child:nth-last-child(2),
      &:first-child:nth-last-child(2) ~ .tabbed-products-block__tab {
        width: calc(50% - 2px);
        &:nth-child(2) {
          width: 50%;
        }
      }
      &:first-child:nth-last-child(4),
      &:first-child:nth-last-child(4) ~ .tabbed-products-block__tab {
        width: calc(50% - 2px);
        &:nth-child(even) {
          width: 50%;
          border-#{$rdirection}: none;
        }
      }
      &:first-child:nth-last-child(5),
      &:first-child:nth-last-child(5) ~ .tabbed-products-block__tab {
        &:nth-child(4) {
          width: calc(50% - 2px);
        }
        &:nth-child(5) {
          width: 50%;
        }
      }
      &:first-child:nth-last-child(3),
      &:first-child:nth-last-child(3) ~ .tabbed-products-block__tab,
      &:first-child:nth-last-child(5),
      &:first-child:nth-last-child(5) ~ .tabbed-products-block__tab,
      &:first-child:nth-last-child(6),
      &:first-child:nth-last-child(6) ~ .tabbed-products-block__tab {
        &:nth-child(3) {
          width: 33.3333%;
          border-#{$rdirection}: none;
        }
      }
      &:first-child:nth-last-child(6),
      &:first-child:nth-last-child(6) ~ .tabbed-products-block__tab {
        &:nth-child(6) {
          width: 33.3333%;
        }
      }
    }
    &__content {
      &.hide-product-budges {
        .product-brief__flag {
          display: none;
        }
      }
    }
    &__content-item {
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition: opacity 1s ease-in-out;
      width: 100%;
      &.current {
        height: auto;
        overflow: visible;
        opacity: 1;
      }
    }
    .product-brief-carousel {
      border-top: none;
      .product-brief__cta--shop-now {
        display: none;
      }
    }
    .grid--mpp__carousel {
      position: relative;
      animation-duration: 1.5s;
      margin: 20px 50px;
      @include breakpoint($medium-up) {
        margin: 40px 90px;
      }
      .carousel-controls {
        top: 0;
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        bottom: 0;
        height: calc(100% - 115px);
        @include breakpoint($medium-up) {
          height: calc(100% - 170px);
        }
        .slick-next,
        .slick-prev {
          top: 45%;
          font-size: 0;
        }
        .slick-next {
          #{$rdirection}: -50px;
          color: $color-button-dark-gray;
          &:hover {
            @include breakpoint($large-up) {
              color: $color-gray;
            }
          }
          @include breakpoint($medium-up) {
            #{$rdirection}: -65px;
          }
        }
        .slick-prev {
          #{$ldirection}: -50px;
          color: $color-button-dark-gray;
          &:hover {
            @include breakpoint($large-up) {
              color: $color-gray;
            }
          }
          @include breakpoint($medium-up) {
            #{$ldirection}: -65px;
          }
        }
      }
      .grid--mpp__items {
        > .slick-list {
          flex-flow: row wrap;
        }
        > .slick-list,
        > .slick-list > .slick-track {
          display: flex;
          flex: 1 0 auto;
        }
        &.slick-initialized {
          .slick-slide {
            display: flex;
            flex: 1 0 auto;
            flex-flow: column;
          }
        }
        .product-brief__container {
          display: flex;
          flex: 1 0 auto;
          flex-flow: column;
          &:hover {
            .product__image-link {
              .product__image {
                &:first-child:not(:only-child) {
                  @include breakpoint($medium-up) {
                    opacity: 0;
                    top: 0;
                    #{$ldirection}: 0;
                    #{$rdirection}: 0;
                  }
                }
                &.product-image--hover {
                  opacity: 1;
                  top: 0;
                  #{$ldirection}: 0;
                  #{$rdirection}: 0;
                }
              }
            }
          }
          .product-brief__name {
            a {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              height: 1.6rem;
              text-align: center;
              margin: 0 auto;
              text-overflow: ellipsis;
              @include breakpoint($medium-up) {
                height: 2.2rem;
              }
            }
          }
          .product__image-link,
          .product-brief__quickshop-launch-wrapper,
          .product-brief__name,
          .product-brief__sku-size,
          .product-brief__price,
          .product-brief__sku-shade {
            display: flex;
            margin-#{$ldirection}: auto;
            margin-#{$rdirection}: auto;
            text-align: center;
            flex: 0 0 auto;
          }
          .product__image-link {
            .product__image {
              object-fit: contain;
              &:first-child {
                transition: opacity 0.3s;
                flex: 0 0 auto;
              }
              &.product-image--hover {
                display: none;
                @include breakpoint($medium-up) {
                  display: flex;
                  opacity: 0;
                  transform: translateX(-100%);
                  transition: opacity 0.3s;
                  flex: 0 0 auto;
                }
              }
            }
          }
          .product-brief__sku-size {
            text-transform: inherit;
            .product-sku-size {
              width: 100%;
            }
          }
          .product-brief__quickshop-launch-wrapper {
            .button {
              margin: 0 auto;
            }
          }
          .product-brief__price,
          .product-brief__sku-shade {
            flex: 1 0 auto;
            -ms-flex-direction: column;
          }
          .product-brief__price {
            flex-flow: column-reverse;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
  // IE9+
  .tab-container-block {
    .grid--mpp__items {
      .product-brief__container {
        .product-brief__name {
          a {
            @include add-ellipsis(1.6rem);
            @include breakpoint($medium-up) {
              height: 2.2rem;
            }
          }
        }
      }
    }
  }
}
